<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center text-center mx-3">
          <div></div>
          <div class="d-flex align-items-center">
            <StorageSpaceIcon :plan="subLevelMap[get(loggedInUser, 'subscription_level')]" />
            <span class="title">{{ modalTitle }} </span>
            <SubPlanBadge
              :plan="subLevelMap[get(loggedInUser, 'subscription_level')]"
              class="ml-2"
              v-if="get(loggedInUser, 'subscription_level') > 0"
            />
          </div>
          <div class="clickable-item" @click.prevent="dismissModal()">
            <i class="ti-close text-black" />
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div class="pb-3 px-4 mt-3">
        <ImageStorageSpace upgrade-tag-bg-color="#fff" :show-title="false" />
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import SubPlanBadge from '@/shared/components/SubPlanBadge.vue';
import StorageSpaceIcon from '@/shared/components/StorageSpaceIcon.vue';
import ImageStorageSpace from '@/shared/components/ImageStorageSpace.vue';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  modalTitle: {
    type: String,
    default: 'My Image Storage Space',
  },
});
const isOpen = toRef(props, 'isOpen');
const emits = defineEmits(['close']);
const subLevelMap = constants.subscriptionLevelsTextMap;
const { user: loggedInUser } = authStore();

const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.dark .toolbar
  --background: #17074c !important
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: calc(85vh - 45px)
  overflow: auto
  overflow-x: hidden

.modal-big
  --width: 540px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.title
  color: #214163
  font-size: 18px
  font-weight: bold
</style>
