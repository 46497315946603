<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex p-3">
          <div class="title mx-auto">{{ isSubmitted ? 'Thanks for reporting' : 'Report' }}</div>
          <div class="d-flex align-items-center">
            <ion-buttons>
              <ion-button @click="dismissModal()">
                <i class="ti-close ml-auto" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="inner-content">
      <div class="d-flex justify-content-center align-items-center py-3" v-if="isLoading">
        <ChLoading size="sm" class="spinner" />
      </div>
      <div v-else>
        <div v-if="isSubmitted">
          <report-submitted-card :category="{}" @dismiss="dismissModal()" />
        </div>
        <div v-else class="px-3 mt-2">
          <p>
            Please complete and submit the form below to report any issue or violation that you encounter. Our team will
            swiftly address your concerns to make your experience with CharacterHub better.
          </p>
          <form @submit.prevent="onSubmit">
            <div class="label mb-2">Profile URL of the user (Optional)</div>
            <ion-input v-model="url" class="c-input d-inline-block mb-2" placeholder="Enter here" />

            <div class="label my-2">Why are you reporting?</div>
            <ion-textarea v-model="comments" class="c-textarea one-input" placeholder="Enter here" required />

            <div style="font-size: 15px">
              <ul class="pl-3 pr-2">
                <li class="mb-2">
                  If you're reporting something on the site, please report the message, post, character, or content
                  directly. We can review much faster if you do.
                </li>
                <li>
                  Please share all useful details and links when possible. We cannot accept screenshots as proof, only
                  stuff on the site or original source links.
                </li>
              </ul>
            </div>

            <div class="mt-2">
              <ion-button style="width: 100%" class="" type="submit">Submit</ion-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { submitUserReport } from '@/shared/actions/report';
import ReportSubmittedCard from '@/shared/components/ReportSubmittedCard.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(['close']);
const isOpen = toRef(props, 'isOpen');
const isSubmitted = ref(false);
const isLoading = ref(false);
const comments = ref('');
const url = ref('');

const resetData = () => {
  isSubmitted.value = false;
  isLoading.value = false;
  comments.value = '';
  url.value = '';
};
const dismissModal = () => {
  emits('close');
};

const onSubmit = async () => {
  await submitUserReport({
    comments: comments.value,
    category_type: 'general',
    profile_url: url.value,
  });

  isSubmitted.value = true;
};

watch(isOpen, () => {
  if (isOpen.value) {
    resetData();
  }
});
</script>

<style lang="sass" scoped>
.label
  font-weight: bold

.dark .toolbar
  --background: #17074c !important

.title
  font-weight: bold
  font-size: 22px

.modal-big
  --width: 500px
  --height: auto
  --border-radius: 9px
  @media(max-width: 570px)
    --width: 100%
    --height: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  overflow: auto
  @media(max-width: 570px)
    height: 100dvh
    height: 100%
</style>
