<template>
  <ion-page class="page scrollable has-text">
    <div class="p-3">
      <div class="d-flex align-items-center mt-3">
        <TopBar :display-full-size="false" />
        <div class="title">Settings and Stuff</div>
      </div>

      <AccountSection title="Theme" :displaySpacing="false">
        <div class="d-flex justify-content-between align-items-center mx-1">
          <div>Dark mode</div>
          <div>
            <ion-button size="small" color="transparent" @click="toggleDarkness" class="mode-btn">
              <inline-svg width="18px" height="18px" :src="mode" />
            </ion-button>
          </div>
        </div>
      </AccountSection>

      <AccountSection title="Account">
        <AccountLink icon="/icons/person-outline.svg" :route="{ name: 'account-settings' }" text="Your Account" />
        <AccountLink
          icon="/icons/email.svg"
          :route="`/account/email/${emailSettingToken}`"
          text="Email Settings"
          v-if="emailSettingToken"
        />
        <AccountLink icon="/icons/email.svg" text="Email Settings" v-else />
        <AccountLink
          icon="/icons/like.svg"
          :route="{ name: 'my-creations', query: { tab: 'reactions' } }"
          text="My Reactions"
        />
        <AccountLink icon="/icons/fanart.svg" :route="{ name: 'fan-work' }" text="My Fanworks" />
        <AccountLink
          icon="/icons/bookmark-account.svg"
          :route="{ name: 'my-creations', query: { tab: 'bookmarks' } }"
          text="My Bookmarks"
        />
      </AccountSection>

      <AccountSection title="Preferences">
        <AccountLink icon="/icons/preferences.svg" :route="{ name: 'user-settings' }" text="Content Preferences" />
        <AccountLink icon="/icons/block.svg" :route="{ name: 'blocked-users' }" text="Blocked Users" />
        <AccountLink
          icon="/icons/storage.svg"
          text="My Storage Space"
          @click="openStorageSpaceModal"
          v-if="featureFlags.storage"
        />
      </AccountSection>

      <AccountSection title="For Fun">
        <AccountLink icon="/icons/museum.svg" route="/hubert-museum" text="Hubert Museum" />
        <AccountLink icon="/icons/key.svg" :route="{ name: 'code' }" text="Enter a Code" />
      </AccountSection>

      <AccountSection title="Other">
        <AccountLink icon="/icons/flag.svg" text="Report a problem" @click="openReportModal" />
        <AccountLink :isLogout="true" icon="/icons/logout.svg" text="Log Out" @click="startLogout" />
      </AccountSection>

      <!-- <section v-if="st === 's'" class="mt-2">
        <a rel="nofollow" :href="stripePortalUrl" class="link clickable text-black">Manage Subscription Info</a>
      </section>
      <section v-if="st === 'p'" class="mt-2">
        <a rel="nofollow" :href="paypalPortalUrl" class="link clickable text-black">Manage Subscription Info</a>
      </section>
      <section v-if="isSubscribed" class="mt-2">
        <span class="clickable" @click="openSubscribeModal($event, 'accountPgChangeSubscriptionLevel')">
          Change Subscription Level&nbsp;
          <div v-if="user?.subscription_level" class="d-inline-block">
            <SubPlanBadge v-if="user?.subscription_level === 1"></SubPlanBadge>
            <SubPlanBadge v-if="user?.subscription_level === 2" plan="ultra"></SubPlanBadge>
          </div>
        </span>
      </section> -->

      <section class="text-center version" v-if="!isMobile">
        <span class="gray small">Ver. 1.0{{ appVer }}</span>
      </section>
    </div>
    <ImageStorageSpaceModal :is-open="isOpenStorageSpaceModal" @close="isOpenStorageSpaceModal = false" />
    <GeneralReportModal :is-open="isOpenReportModal" @close="closeReportModal" />
  </ion-page>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import { openSubscribeModal } from '@/shared/utils/modals';
import ImageStorageSpaceModal from '@/shared/modals/ImageStorageSpaceModal.vue';
import { featureFlags } from '@/shared/config/feature-flags';
import SubPlanBadge from '@/shared/components/SubPlanBadge.vue';
import TopBar from '@/shared/components/TopBar.vue';
import GeneralReportModal from '@/shared/modals/GeneralReportModal.vue';
import { useMobile } from '~/apps/mobile/composables/useMobile';
import { confirmModifySubscriptionPaypal } from '@/shared/actions/payments';
import { toast } from '@/shared/native/toast';
import { mainStore } from '~/shared/pinia-store/main';
import AccountSection from './AccountSection.vue';
import AccountLink from './AcccountLink.vue';
const emailSettingToken = ref('');
const isOpenStorageSpaceModal = ref(false);
const router = useRouter();
const toggleDarkness = mainStore().toggleDarkness;
const paypalPortalUrl = computed(() => {
  const {
    public: { env },
  } = useRuntimeConfig();
  if (env === 'development' || env === 'staging') return 'https://www.sandbox.paypal.com/myaccount/autopay/';
  return 'https://www.paypal.com/myaccount/autopay/';
});

const dark = computed(() => {
  return mainStore().dark.value;
});

const isOpenReportModal = ref(false);
const emits = defineEmits(['close']);

const { isMobile } = useMobile();

const mode = computed(() => {
  return dark.value ? '/icons/moon-outline.svg' : '/icons/sunny-outline.svg';
});
const {
  public: { stripePortalUrl },
} = useRuntimeConfig();

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const isSubscribed = computed(() => {
  // pro is an object. If it has ANY keys, user is subscribed
  return user.value?.subscription_level;
});

const st = computed(() => {
  return user.value?.st;
});

const openReportModal = () => {
  isOpenReportModal.value = true;
};
const closeReportModal = () => {
  isOpenReportModal.value = false;
  emits('close');
};

const openStorageSpaceModal = () => {
  isOpenStorageSpaceModal.value = true;
};

const startLogout = () => {
  const { logout } = authStore();
  logout();
};

const appVer = computed(() => {
  try {
    const {
      public: { buildNumber },
    } = useRuntimeConfig();
    return buildNumber;
  } catch (error) {
    return 0;
  }
});

onMounted(async () => {
  const { fetchEmailUnsubToken } = authStore();
  emailSettingToken.value = await fetchEmailUnsubToken();
  const router = useRouter();
  if (router.currentRoute.value?.query?.ssm) {
    openSubscribeModal(null, 'direct');
  }
  if (router.currentRoute.value?.query?.paypalsubchanged) {
    const sid = router.currentRoute.value?.query.subscription_id as string;
    await confirmModifySubscriptionPaypal(sid);
    toast.show('Subscription modified', 'nonative', 'primary');
  }
});
</script>

<style lang="sass" scoped>
.version
  padding-top: 20px
.small-icon
  font-size: 18px
.mode-btn
  color: #715BBB
  height: 43px
  padding: 0px
  margin: 0px
  border: 2px solid #e4e4e7
  border-radius: 9px
.icon
  font-size: 22px

.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none
.title
  text-align: center
  font-size: 24px
  font-weight: bold
  @media(max-width: 400px)
    font-size: 20px

.hoverable:hover
  color: gray
  cursor: pointer
.gray
  color: gray
</style>
